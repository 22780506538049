import React, { useState } from 'react';
import { View, Text, TextInput, Theme, variantProvider, logger } from '@/app';

import { AccountTabs } from '@/shared';

import { onMount, onUpdate, useSearchParams, useDebounce } from '@codeleap/common';

import { TabsSkeleton } from '@/skeletons';

import { useOffers } from '@/services/api';

const Offers: React.FC = (props) => {
  const { profile } = props;

  const [search, setSearch] = useState('');
  const [value] = useDebounce(search, 500);

  const [params, setParams] = useSearchParams({
    tab: 1,
    page: 1,
  });

  const pageNumberToOffeset = (pageNumber: number) => {
    return (pageNumber - 1) * 10;
  };

  const {
    data: { offersReceived, offersSent },
    queries: Offers,
    loading,
  } = useOffers();

  const hasOffers =
    offersReceived?.results?.length > 0 ||
    offersSent?.results?.length > 0 ||
    params.page !== 1;

  onMount(() => {
    Offers.getByTypes.send({
      profile: profile?.id,
      // offset: pageNumberToOffeset(params.page),
    });
  });

  onUpdate(() => {
    Offers.getByTypes.send({
      profile: profile?.id,
      title: params.title,
    });
  }, [params.title]);

  onUpdate(() => {
    if (profile) {
      Offers.getByTypes.send({
        profile: profile.id,
        offset: pageNumberToOffeset(params.page),
      });
    }
  }, [params.page]);

  onUpdate(() => {
    logger.log({ offersReceived, offersSent });
  }, [offersReceived, offersSent]);

  onUpdate(() => {
    setParams({
      ...params,
      title: value,
    });
  }, [value]);

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <View variants={['column', 'fullWidth', 'fullHeight']}>
      <View
        responsiveVariants={{
          mid: ['column'],
        }}
        variants={['fullWidth', 'justifySpaceBetween', 'alignCenter']}
      >
        <Text variants={['h2', 'marginBottom:2']} text='Offers' />
        <TextInput
          styles={{ wrapper: styles.searchInput, icon: styles.icon }}
          placeholder='Search'
          variants={['withHover']}
          onChange={(e) => {
            handleChangeSearch(e);
          }}
          leftIcon={{
            name: 'search',
          }}
        />
      </View>
      <TabsSkeleton ready={!loading}>
        <AccountTabs
          isOffer
          tab={params.tab}
          params={params}
          setParams={setParams}
          Items={Offers}
          hasItems={hasOffers}
          itemsOne={offersReceived}
          itemsTwo={offersSent}
        />
      </TabsSkeleton>
    </View>
  );
};

const styles = variantProvider.createComponentStyle(
  {
    searchInput: {
      width: '30%',
      [Theme.media.down('mid')]: {
        width: '100%',
      },
    },
    icon: {
      color: 'rgba(0,0,0,0.4)',
      stroke: 'rgba(0,0,0,0.4)',
    },
  },
  true
);

export default Offers;
