import React, { useState } from 'react'
import { View, Text, Theme, Drawer, variantProvider, logger } from '@/app'
import { FeedbackModal, NoItems, ProductInfo } from '@/shared'
import { onMount, onUpdate, useDebounce } from '@codeleap/common'
import { MessagesSkeleton } from '@/skeletons'

import { Chat, AllMessages, Info } from '@/layout/Account'

import { useAppSelector } from '@/lib'
import { useOffers, useMessages } from '@/services/api'

type MessagesProps = any

const Messages: MessagesProps = (props) => {
  const { profile } = useAppSelector((store) => store.Session)

  const [showInfo, setShowInfo] = useState(false)
  const [currOffer, setCurrOffer] = useState(null)
  const [tab, setTab] = useState(1)
  const [feedback, setFeedback] = useState(0)
  const [search, setSearch] = useState('')
  const [value] = useDebounce(search, 500)

  const isSmall = Theme.hooks.down('mid')

  const {
    data: { offers },
    queries: Offers,
    loading,
  } = useOffers()

  const {
    data: { messages },
    queries: Messages,
    loading: loadingMessages,
  } = useMessages()

  onMount(() => {
    if (profile) {
      Offers.query.send({ status: 'CP,AC' })
    }
  })

  onUpdate(() => {
    Offers.query.send({
      status: 'CP,AC',
      title: value,
    })
  }, [value])

  // onUpdate(() => {
  //   setTimeout(() => {
  //     if (currOffer) {
  //       Messages.query.send({ offer: currOffer?.id })
  //     }
  //   }, 60000)
  // }, [currOffer])

  const renderChat = () => {
    if (!currOffer) {
      return (
        <View
          css={[
            !isSmall || (isSmall && tab === 2)
              ? styles.noMessages
              : styles.noMessagesDisabled,
          ]}
          variants={['alignCenter', 'justifyCenter']}
        >
          <Text
            variants={['h3', 'bold']}
            text='Click on a message to open the chat'
          />
        </View>
      )
    } else {
      return (
        <Chat
          tab={tab}
          currOffer={currOffer}
          showInfo={showInfo}
          setShowInfo={setShowInfo}
          setCurrOffer={setCurrOffer}
          setTab={setTab}
          messages={messages}
          Messages={Messages}
          loading={loadingMessages}
        />
      )
    }
  }

  return (
    <>
      <View css={styles.wrapper} variants={['fullWidth', 'gap:2', 'marginBottom:6']}>
        <AllMessages
          offers={offers}
          tab={tab}
          setTab={setTab}
          currOffer={currOffer}
          setCurrOffer={setCurrOffer}
          Messages={Messages}
          loading={loading}
          setSearch={setSearch}
        />

        {offers.length > 0 && renderChat()}
      </View>
      <Drawer
        open={showInfo}
        toggle={() => setShowInfo(!showInfo)}
        position={'right'}
        styles={{
          header: { display: 'none' },
          box: styles.drawerBox,
          // wrapper: { width: '100%' },
        }}
      >
        <ProductInfo
          showInfo={showInfo}
          setShowInfo={setShowInfo}
          Offers={Offers}
          currOffer={currOffer}
          setCurrOffer={setCurrOffer}
          feedback={feedback}
          isMessage
          isDrawer
          wrapperStyle={styles.infoWrapper}
        />
      </Drawer>
      <FeedbackModal
        currOffer={currOffer}
        origin={profile?.id}
        feedback={feedback}
        setFeedback={setFeedback}
        setCurrOffer={setCurrOffer}
      />
    </>
  )
}

export default Messages

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      // ...Theme.spacing.marginVertical(4),
      // overflow: 'hidden',
      height: '100%',
    },
    noMessages: {
      width: 500,
    },
    noMessagesDisabled: {
      display: 'none',
    },
    infoWrapperDisabled: {
      display: 'none',
    },
    infoWrapper: {
      width: '100%',
    },
    drawerBox: {
      ...Theme.spacing.paddingHorizontal(2),
      backgroundColor: Theme.colors.light.white,
      width: '30%',
      [Theme.media.down('mid')]: {
        width: '100%',
      },
    },
  },
  true,
)
