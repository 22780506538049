import React, { useState } from 'react'
import { logger, View, Text, TextInput, Theme, variantProvider } from '@/app'

import { AccountTabs } from '@/shared'
import { TabsSkeleton } from '@/skeletons'

import { onMount, onUpdate, useDebounce, useSearchParams } from '@codeleap/common'

import { useListings } from '@/services/api'

const Inventory: React.FC = (props) => {
  const { profile } = props

  const [search, setSearch] = useState('')
  const [value] = useDebounce(search, 500)

  const {
    data: { listingsItems, listingsBundles },
    queries: Listings,
    loading,
  } = useListings()

  const [params, setParams] = useSearchParams({
    tab: 1,
    page: 1,
  })

  const pageNumberToOffeset = (pageNumber: number) => {
    return (pageNumber - 1) * 10
  }

  const hasItems =
    listingsItems?.results?.length > 0 ||
    listingsBundles?.results?.length > 0 ||
    params.page !== 1

  onMount(() => {
    Listings.getByTypes.send({
      profile: profile?.id,
      sold: false,
      // offset: pageNumberToOffeset(params.page),
    })
  })

  onUpdate(() => {
    Listings.getByTypes.send({
      profile: profile?.id,
      title: params.title,
      sold: false,
    })
  }, [params.title])

  onUpdate(() => {
    if (profile) {
      Listings.getByTypes.send({
        profile: profile.id,
        offset: pageNumberToOffeset(params.page),
      })
    }
  }, [params.page])

  onUpdate(() => {
    setParams({
      ...params,
      title: value,
    })
  }, [value])

  const handleChangeSearch = (e) => {
    setSearch(e.target.value)
  }

  return (
    <View css={styles.wrapper} variants={['column', 'fullWidth', 'fullHeight']}>
      <View
        responsiveVariants={{
          mid: ['column'],
        }}
        variants={['fullWidth', 'justifySpaceBetween', 'alignCenter']}
      >
        <Text variants={['h2', 'marginBottom:2']} text='Inventory' />
        <TextInput
          styles={{ wrapper: styles.searchInput, icon: styles.icon }}
          placeholder='Search'
          onChange={(e) => {
            handleChangeSearch(e)
          }}
          variants={['withHover']}
          leftIcon={{
            name: 'search',
          }}
        />
      </View>
      <TabsSkeleton ready={!loading}>
        <AccountTabs
          tab={params.tab}
          params={params}
          setParams={setParams}
          Items={Listings}
          hasItems={hasItems}
          itemsOne={listingsItems}
          itemsTwo={listingsBundles}
        />
      </TabsSkeleton>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      // scrollbarGutter: 'stable',
      // backgroundColor: 'red',
      // minHeight: '80vh',
    },
    searchInput: {
      width: '30%',
      [Theme.media.down('mid')]: {
        width: '100%',
      },
    },
    icon: {
      color: 'rgba(0,0,0,0.4)',
      stroke: 'rgba(0,0,0,0.4)',
    },
  },
  true,
)

export default Inventory
