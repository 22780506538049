import React, { useState } from 'react'
import { logger, View, Text, TextInput, Touchable, Theme, variantProvider } from '@/app'
import { useAppSelector } from '@/lib'
import { NoItems, Link } from '@/shared'
import { Notifications } from '@/redux'

const NotificationsPage = () => {
  const { notifications } = useAppSelector((store) => store.Notifications)

  const handleUpdateNotificationStatus = (notif) => {
    logger.log({ notif })
    Notifications.markRead({ id: notif.id })
  }

  const renderNotificationItem = (notification) => {
    return (
      <Touchable onPress={() => { handleUpdateNotificationStatus(notification) }}>
        <Link css={styles.link} to={`${notification?.extra_data?.url}`}>
          <View css={styles.notificationItem} variants={['fullWidth', 'padding:1', 'gap:1']}>
            <View css={styles.badge} variants={['alignSelfCenter', 'alignCenter']} />
            <View variants={['column', 'gap:0.5']}>
              <View variants={['gap:0.5']}>
                <Text variants={['bold']} text={`${notification?.extra_data?.title}`} />
              </View>
              <View>
                <Text text={`${notification?.extra_data?.body}`}/>
              </View>
            </View>
          </View>
        </Link>
      </Touchable>
    )
  }

  return (
    <View variants={['fullWidth', 'column']}>
      <Text variants={['h2', 'marginBottom:2']} text='Notifications' />
      {notifications.length <= 0 &&
      <NoItems
        icon={'noNotifications'}
        title={"You've got nothing here"}
        subtitle={
          'Make or accept an offer to start a conversation with a seller or a buyer.'
        }
        wrapperStyle={styles.noItemsWrapper}
      />
      }

      <View variants={['column', 'gap:2']}>
        {notifications.map(notification => renderNotificationItem(notification))}
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      // width: ALL_MESSAGES_WIDTH,
      [Theme.media.down('mid')]: {
        width: '100%',
      },
    },
    link: {
      width: '100%',
    },
    wrapperDisabled: {
      display: 'none',
    },
    messagesWrapper: {
      height: '60vh',
      overflowY: 'scroll',
    },
    icon: {
      color: 'rgba(0,0,0,0.4)',
      stroke: 'rgba(0,0,0,0.4)',
    },
    whitePre: {
      // whiteSpace: 'pre',
    },
    noItemsWrapper: {
      maxWidth: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    notificationItem: {
      backgroundColor: Theme.colors.light.veryLightGray,
    },
    badge: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: '#E25656',
    },
  },
  true,
)

export default NotificationsPage
