import React, { useState } from 'react'
import { Menu, Theme, variantProvider, View } from '@/app'
import { Auth, Page } from '@/shared'
import { useAppSelector } from '@/lib'
import { useForm, onUpdate } from '@codeleap/common'

import { AccountMenu } from '@/layout/Account'

import AccountInventory from './_inventory'
import AccountOffers from './_offers'
import AccountMessages from './_messages'
import AccountEdit from './_edit'
import AccountNotifications from './_notifications'

const AccountPage: React.FC = () => {
  const [file, setFile] = useState(null)
  const [postFile, setPostFile] = useState(null)

  const { profile } = useAppSelector((store) => store.Session)

  return (
    <View variants={['fullWidth']}>
      <Auth>
        <Page
          withRouter
          basePath='/account'
          title='Account'
          renderContentWrapper={({
            children,
            menuItems,
            showMenu = true,
            styles,
          }) => (
            <View
              css={pageStyles.wrapper}
              variants={['fullWidth', 'marginTop:12', 'gap:4']}
            >
              <>
                <AccountMenu
                  menuItems={menuItems}
                  styles={styles}
                  profile={profile}
                  file={file}
                  setPostFile={setPostFile}
                  setFile={setFile}
                  showMenu={showMenu}
                />
              </>

              <View variants={['fullWidth']}>{children}</View>
            </View>
          )}
        >
          <AccountInventory
            menuIcon='inventory'
            title='Inventory'
            path='/inventory'
            profile={profile}
          />
          <AccountNotifications menuIcon={'bell'} title='Notifications' path={'/notifications'} />
          <AccountOffers
            menuIcon='dollar'
            title='Offers'
            path='/offers'
            profile={profile}
          />
          <AccountMessages
            menuIcon='message'
            title='Messages'
            path='/messages'

          />
          <AccountEdit
            menuIcon='user'
            title='Account'
            path='/edit'
            profile={profile}
            file={file}
            setFile={setFile}
            setPostFile={setPostFile}
            postFile={postFile}
            defaultPath
          />
        </Page>
      </Auth>
    </View>
  )
}

const pageStyles = variantProvider.createComponentStyle(
  {
    wrapper: {
      minHeight: '80vh',
      [Theme.media.down('mid')]: {
        gap: Theme.spacing.value(0),
      },
    },
  },
  true,
)

export default AccountPage
