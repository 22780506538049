import React from 'react'
import { Button, logger, View, Text, Theme, variantProvider } from '@/app'
import { LocationsModal, Avatar } from '@/shared'
import { Session, Locations, AppStatus } from '@/redux'
import { useForm, onUpdate } from '@codeleap/common'
import { accountForm, profileLocationsForm, useAppSelector, mainLocationForm } from '@/lib'
import { getDifference } from '@/lib/utils/helpers'
import { v4 } from 'uuid'

import { navigate } from 'gatsby'

import { LocationsForm, AccountForm } from '@/layout/Account'

import { useProfiles } from '@/services/api'
import { sendPasswordReset } from '@/services/authentication'

export type AccountProps = {
  file?: any
  setFile?: React.Dispatch<React.SetStateAction<any>>
  setPostFile?: React.Dispatch<React.SetStateAction<any>>
  postFile?: any
}

const Account: React.FC<AccountProps> = (props) => {
  const { file, setFile, setPostFile, postFile } = props
  const { profile } = useAppSelector((store) => store.Session)
  const { locations } = useAppSelector((store) => store.Locations)
  const { options } = useAppSelector((store) => store.Categories)

  const currenciesOptions = options?.find(opt => opt.title === 'Currency Name')?.selects

  const currencyOptions = currenciesOptions?.map((currency) => { return { value: currency.id, label: `${currency.title}` } })

  logger.log({ currencyOptions, options })

  const isSmall = Theme.hooks.down('mid')

  const {
    queries: Profiles,
  } = useProfiles()

  const form = useForm(accountForm, {
    output: 'multipart',
    validateOn: 'blur',
    initialState: {
      username: profile?.username,
      email: profile?.email,
      avatar: '',
      data: {

        currency: profile?.data?.currency,
        distance_value: profile?.data?.distance_value,
        distance_type: profile?.data?.distance_type,
      },
    },
  })

  logger.log({ profileForm: form })

  const locationsForm = useForm(profileLocationsForm, {
    output: 'json',
    validateOn: 'blur',
    initialState: { locations },
  })

  const locationsFormValues = locationsForm.values.locations

  const otherLocations = locationsFormValues?.filter(loc => loc.main_location === false)

  const mainLocation = locationsFormValues?.find(loc => loc.main_location === true)

  onUpdate(() => {
    form.setFieldValue('avatar', postFile)
  }, [postFile])

  onUpdate(() => {
    locationsFormValues.forEach(function (row, index) {
      row.row_number = index
    })

    logger.log({ locationsFormValues })
  }, [locationsFormValues])

  const handleCreateLocation = (form) => {
    const updatedForm = { ...form, main_location: false }
    const updatedLocations = [...locationsFormValues, updatedForm]
    AppStatus.toggleNewLocationModal()
    locationsForm.setFieldValue('locations', updatedLocations)
  }

  const handleResetPassword = () => {
    sendPasswordReset(profile?.email)
  }

  const handleLogout = () => {
    Session.logout()
    setTimeout(() => {
      navigate('/')
    }, 2000)
  }

  const handleUpdateProfile = async () => {
    const allLocations = [...otherLocations, mainLocation]
    const locationsRedux = locations.filter(loc => loc.main_location === false)

    const locationsToCreate = allLocations.filter(loc => loc?.id === undefined)

    const locationsToDelete = getDifference(locationsRedux, otherLocations)

    const locationsToUpdate = getDifference(allLocations, locationsToCreate)

    const isValid = form.validateAll(true)

    logger.log({ locationsToCreate, allLocations, locationsFormValues })

    if (isValid) {
      try {
        Locations.updateProfileLocations({
          data: {
            locationsCreate: locationsToCreate,
            locationsUpdate: locationsToUpdate,
            locationsDelete: locationsToDelete,
          },
        })

        Session.updateProfile({ data: { ...form.values, avatar: postFile }})

      } catch (err) {
        logger.log(err)
      }

    }
  }

  const handleImageChange = (args) => {
    setFile(args.preview)
    setPostFile(args.file)
  }

  return (
    <View variants={['column', 'gap:3', 'fullWidth']}>
      {isSmall && (
        <View variants={['alignCenter', 'justifyCenter']}>
          <Avatar
            profile={{
              avatar: file ? file : profile?.avatar,
              firstName: profile?.first_name,
              username: profile?.username,
            }}
            styles={{
              icon: styles.avatar,
            }}
            variants={['large', 'bigRadius']}
            onChange={handleImageChange}
          />
        </View>
      )}
      <AccountForm form={form} currencyOptions={currencyOptions} />
      <LocationsForm Profiles={Profiles} locationsForm={locationsForm} />
      <View
        variants={['column', 'fullWidth', 'gap:1']}
        css={styles.buttonsWrapper}
      >
        <Button text='Save' onPress={handleUpdateProfile} />
        <Button
          variants={['transparent']}
          responsiveVariants={{
            mid: ['fullWidth'],
          }}
          text='Reset Password'
          onPress={handleResetPassword}
        />
        <Button
          variants={['transparentNoBorder']}
          text='Logout'
          onPress={handleLogout}
        />
      </View>
      <LocationsModal onCreateLocation={handleCreateLocation} />
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    buttonsWrapper: {
      width: '35%',
      [Theme.media.down('mid')]: {
        width: '100%',
      },
    },
    avatar: {
      width: '100%',
      height: '100%',
      display: 'unset',
    },
  },
  true,
)

export default Account
